@font-face {
    font-family: 'nexa-bold';
    src: url('../assets/nexa_bold-webfont.eot');
    src: url('../assets//nexa_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets//nexa_bold-webfont.woff2') format('woff2'),
         url('../assets//nexa_bold-webfont.woff') format('woff'),
         url('../assets//nexa_bold-webfont.ttf') format('truetype'),
         url('../assets//nexa_bold-webfont.svg#nexa_boldregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
body {
    font-family: "proxima-nova", sans-serif;
    font-weight: normal;
    color: #222; /* so you can read it in the console */
    font-size: 20px;
}
h1, h2, h3, h4 {
    font-family: "nexa-bold", sans-serif;
    font-weight: normal;
    line-height: 1;
}
h1, h2 {
    font-size: 50px;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight,
h2.title > span {
    color: #004658; /*dark blue*/
}
.dark .highlight,
.dark h2.title > span {
    color: #8dc6e8; /*light blue*/
}
.dark h2.title > span {
    text-decoration: underline;
}
.underline {
    position: relative;
    text-decoration: underline;
}
.mouth.section h2 {
    font-size: 6vh;
    text-shadow:
    -1px -1px 0 #F5333F,
     1px -1px 0 #F5333F,
     -1px 1px 0 #F5333F,
      1px 1px 0 #F5333F;
}
.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: #FFF;
    background-color: grey;
    border: 1px solid grey;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover {
    color: grey;
    background-color: transparent;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}
img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

.circle-image {
    position: relative;
    width: 80%;
    max-width: 100%;
    background-color: #e5e6e6;
    border: 8px solid #FFF;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;
}
.three-col .circle-image,
.three-col .graphic {
    border-width: 12px;
}
.four-col .circle-image,
.four-col .graphic {
    width: 90%;
}
.circle-image:before { /* ratio */
    content:"";
    display: block;
    width: 100%;
    padding-top: 90%;
}
.circle-image img { /* position inside square */
    position: absolute;
    top:0;
    left:0;
    min-width: 100%;
    min-height: 100%;
}

.center-wrapper {
    text-align: center;
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
.two-col,
.three-col,
.four-col {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    padding: 1%;
    box-sizing: border-box;
}
.two-col {
    width: 48%;
}
.three-col {
    width: 30%;
}
.four-col {
    width: 23%;
}
.two-col h2,
.three-col h2,
.four-col  h2 {
    margin-bottom: 0px;
}
.two-col h4,
.three-col h4,
.four-col h4 {
    margin: 10px 0px;
}
@media only screen and (max-width: 1024px){
    .four-col {
        width: 48%;
    }
}
@media only screen and (max-width: 960px){
    .center-wrapper {
        justify-content: space-evenly;
    }

}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
@media only screen and (max-width: 550px){
    .center-wrapper {
        display: block;
    }
    .two-col,
    .three-col,
    .four-col{
        width: 100%;
        padding: 0px;
    }
    .four-col {
        padding: 0px 10px;
    }
}